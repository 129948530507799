import React from 'react';
import { Link } from 'gatsby';

const Thankyou = () => {
  return (
    <div className="text-center mt-4">
      <h2 className="max-w-4xl mx-auto mb-4 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">Thank you for your purchase ...</h2>
      <p className= "max-w-2xl mx-auto px-6 text-lg text-gray-600 font-inter mb-4">
        You should recieve an email in a few minutes with login details if you purchased this product without
        an account on devwares 
        <br />  <br />
        Else you will receive a confirmation email from devwares acknowledging the purchase. 
        Click on the button below to access windframe premium if you are already logged in or use the login button to login to your account.
      </p>
      <a href="https://windframe.devwares.com" target="_blank" rel="noreferrer"
        className="mb-3 sm:mb-0 inline-flex items-center justify-center w-full px-3 py-2 text-lg 
        font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent 
        sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2
         focus:ring-gray-900 mr-2">
        Go to windframe
      </a>
      <Link to="https://windframe.devwares.com/sign-in"
        className="inline-flex items-center justify-center w-full px-3 py-2 text-lg 
        font-bold text-gray-900 hover:text-white transition-all duration-200 
        bg-gray-100 border-2 border-gray-900 sm:w-auto rounded-xl font-pj
         hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 
         focus:ring-gray-900">Go to Login</Link>  <br />
    </div>
  );
};

export default Thankyou;
